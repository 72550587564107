
@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), 
  url("../assets/fonts/Nunito-Light.ttf");
  font-weight: 200;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), 
  url("../assets/fonts/Nunito-Regular.ttf");
  font-weight: 500;
}

@font-face {
  font-family:'Nunito';
  src: local('Nunito'), 
  url("../assets/fonts/Nunito-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), 
  url("../assets/fonts/Nunito-ExtraBold.ttf");
  font-weight: 900;
}