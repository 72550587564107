
body{
  font-family: "Nunito";
}
.container{
  height: 100vh;
  width: 100vw;
  background-color: var(--white);
  display:flex;
  flex:1;
  justify-content:center;
  align-items: center;
}
.login-warpper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-width: 100%;
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  padding: 20px;
  border-radius: 5px;
  min-width: 300px;
  box-shadow: 0px 2px 3px rgba(0,0,0,.2);
}

.separator {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  color: var(--seperator-color);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
}
.separator::before, .separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--seperator-color);
}
.separator::before {
  margin-right: 1.45em;
}
.separator::after {
  margin-left: 1.45em;
}
.tooltip{
  font-size: 16px;
  text-align: center;
  line-height: 18px;
  padding: 5px;
  font-weight: bold;
}
.MuiTooltip-tooltip {
  display: flex;
  color: var(--white);
  padding: 4px 8px;
  font-size: 0.625rem;
  max-width: 300px;
  word-wrap: break-word;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  background-color: rgba(97, 97, 97, 0.9);
  justify-content: center;
  align-items: center;
}
.formGroup{
  display:flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.fakeInput{
  display: flex;
  justify-content: center;
  align-items: center;
  background:rgba(0,0,0,0.05);
  height: 50px;
  overflow: hidden;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /*border-bottom: 2px solid;*/
  margin-bottom: 10px;
  box-shadow: 0px 2px 1px rgba(0,0,0,.07);
  font-size: 16px;
  font-weight: bold;
}
.headliner{
  font-size: 46px;
  padding:0;
  margin-top:20px;
  margin-bottom:20px;
}
.button{
  text-transform: uppercase;
  font-weight: bold!important;
}
.infoBox{
  background: var(--infoBox-background);
  width: 100%;
  border-radius: 5px;
  padding: 30px;
  color: var(--infoBox-color);
}
.infoBox p{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.infoBox p b{
  margin-right: 10px;
}
.customButton{
  text-decoration: none;
  font-weight: bold;
}
.customButtonLight{
  text-decoration: none;
  font-weight: bold;
  color:var(--white);
}
.uploadkey{
  display: flex;
  width: 100vw;
  height: 100vh;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.uploadFile{
  margin-bottom: 24px;
}
.link{
  text-decoration: none;
  color: var(--black);
}
.resultsbox{
  display:flex;
  flex-direction: row;
  align-items: center;
}
.resultsbox b{
  font-size: 32px;
  padding: 22px;
}

.resultsboxsm{
  display:flex;
  flex-direction: row;
  align-items: center;
}
.resultsboxsm b{
  font-size: 22px;
  padding: 22px;
}

::-webkit-file-upload-button {
  display: none;
  visibility: hidden;
}
.inputFileWrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.infoFile{
  font-size: 10px;
  padding-left: 3px;
  padding-top: 6px;
}
input[type="file"]{
  background-color: var(--white-smoke);
  width: 100%;
  padding: 22px;
  position: relative;
  flex-direction: row-reverse;
  border-radius: 4px;
  color: var(--dim-gray);
  height: 16px;
  cursor: pointer;
  margin-right: 24px;
}

.filter-wrapper .MuiFilledInput-root {
  background: var(--infoBox-background);
}

.filter-wrapper .MuiFilledInput-root:hover {
  background: var(--infoBox-background);
}
