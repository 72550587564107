:root {
  /* Font style */
  --font-family-base: 'Roboto', sans-serif;

  /* Color variables */
  --white: #fff;
  --black: #000;
  --seperator-color: rgba(16, 58, 122, 1);
  --white-smoke: #F4F4F4;
  --dim-gray: #626262;
  --navy-blue: #084b8e;
  --blue: #1976d2;
  --status-green: #00d020;
  --status-orange: #ff8900;
  --red: #C70000;
  --infoBox-background: #E6EAF2;
  --infoBox-color: #103A7A;
}
